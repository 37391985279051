import * as React from 'react';
import styled from 'styled-components';
import { media } from '../../../helpers';

const StyledIngress = styled.p`
	font-family: ${props => props.theme.fonts.secondary};
	font-size: 18px;
	color: ${props => props.theme.colors.bodyText};
	line-height: 1.17;
	margin: 15px auto;
	text-align: center;
	padding: 0 20px;
	width: 100%;
	white-space: pre-wrap;

	${media.tablet`
	  padding: 0 50px;
	`};

	${media.desktop`
		margin: 17px auto;
	  padding: 0;
		font-size: 21px;
		line-height: 1.14;
		max-width: 750px;
	`};
`;

const Ingress: React.FC<{ className?: string }> = ({ children, className }) => (
	<StyledIngress className={className}>{children}</StyledIngress>
);

export default Ingress;
